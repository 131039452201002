export const COLORS = {
    main: "#b385fd",
    glob: "#073b4c",
    // yellow: "#FFF61D",
    yellow: "#ffd166",
    purple: "#B950F9",
    // orange: "#FF932F",
    orange: "#f78c6b",
    // green: "#04FF87",
    green: "#06d6a0",
    // second: "#ffabf4",
    second: "#ef476f",
    light: "#fff6f4",
    blue: "#0883ff",
    bluelow:"#118ab2",
    dark: "#072A40",
    transparent: "#03d97d33",
  } as const;